import React, {Component} from 'react';
import queryString from "query-string";
import {withRouter} from 'react-router';
import {POST} from "./services/rest.service";
import fashionPlaceholderImage from './assets/fashion.svg';
import RecipesPlaceholderImage from './assets/recipes.svg';
import genrealplaceholderImage from './assets/veegsshop.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const currencyFormatter = (value, currency) => {
  try {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    }).format(value);
  } catch (e) {
    console.log("value", value);
    console.log("currency", currency);
    console.error(e);
  }
};

class App extends Component {

  state = {
    products: [],
    totalProducts: -1,

    page: 0,
    limit: 10,
    totalPages: -1,

    description: '',
    brandName: '',

    parsed: null,

    isCollapsed: false,

    isLoading: false,
  };

  componentDidMount() {
    const queryParams = this.props.location.search;
    const parsed = queryString.parse(queryParams);
    if (parsed && parsed.description) {
     this.setState({
       description: parsed.description
     }, () => {
       this.getPage();
     })
    } else {
      this.getPage();
    }
  }

  getPage = () => {
    const {page, limit, description} = this.state;
    this.setState({isLoading: true});

    POST(`/productOrRecipe`, {page: Number(page + 1), limit, description})
        .then((response) => response.data)
        .then((data) => {
          this.setState({
            page: Number(page + 1),
            description: description,
            isLoading: false,

            products: this.state.products.concat(data.items),
            totalPages: data.totalPages,
            totalProducts: data.totalCount,

          });
        })
        .catch((err) => {
          console.error("err", err);
          this.setState({isLoading: false});
        });
  };

  render() {
    const {products, totalProducts, isLoading, isCollapsed} = this.state;
   if(products.length<=0)
   {
     return null
   }
    return (
<div className="veegs-wrapper" id={products.length<=0 ? "veegs-wrapper-nodata" :"veegs-wrapper-data"}>
            <div className="header">
            <div className="header-bar flexbox">
              <a className="logo-text" href={'https://www.veegs.shop'} target={'_blank'} rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="59" height="15" viewBox="0 0 59 15"><path d="M.8 15V0h3.3v13.3h1.7c.5 0 .9-.2 1.2-.5s.5-.7.5-1.2V0h3.3v9.3c0 1.5-.6 2.9-1.7 4S6.6 15 5.1 15H.8zM14.2 15V0h8.3v1.7h-5v5h3.3v1.7h-3.3v5h5v1.7h-8.3zM25.8 15V0h8.3v1.7h-5v5h3.3v1.7h-3.3v5h5v1.7h-8.3zM37.5 11.7V3.4c0-.9.3-1.7 1-2.3s1.4-1 2.4-1h3.3c2.1 0 3.2 1.1 3.3 3.3h-1.7c-.1-1.1-.7-1.7-1.7-1.7h-1.7c-.5 0-.8.2-1.2.5s-.5.7-.5 1.2v8.3c0 .5.2.8.5 1.2s.7.5 1.2.5c1.1-.1 1.6-.6 1.7-1.7V8.4h-1.7V6.7h5v5c0 1.1-.4 1.9-1.1 2.5s-1.5.8-2.3.8h-3.3c-.9 0-1.7-.3-2.3-1s-.9-1.4-.9-2.3zM50.8 11.7h1.7v.3c0 .5.2.8.5 1s.7.4 1.1.4c.6 0 1-.1 1.3-.4s.4-.7.4-1.3c0-.4-.2-.8-.5-1.2l-3.5-4c-.7-.7-1-1.5-1-2.4v-.8c0-.9.3-1.7 1-2.3s1.4-1 2.4-1h1.7c2.1 0 3.2 1.1 3.3 3.3h-1.7c-.1-1.1-.7-1.7-1.6-1.7-.6 0-1 .1-1.3.4s-.4.7-.4 1.3c0 .4.2.8.5 1.2l3.5 4c.7.7 1 1.5 1 2.4v.8c0 .9-.3 1.7-1 2.3s-1.4 1-2.4 1h-1.7c-1.1 0-1.9-.3-2.5-.8s-.8-1.4-.8-2.5z"/></svg>
              </a>
              <span className="lh-0 text-right">
                  <span>&nbsp;</span>
              </span>
              <span className="lh-0 text-right">
                  <span>&nbsp;</span>
              </span>
            </div>
          </div>
          <div className={"content content-scroll show-donate " + (isCollapsed ? 'd-none' : 'd-block')}>
            {
              products.map((product, index) => {
                let placeholderImage;

                switch (product.type) {
                 
                  case 'PRODUCT':
                  placeholderImage = fashionPlaceholderImage;
                  break;
                  case 'RECIPE':
                  placeholderImage = RecipesPlaceholderImage;
                  break;
                  default:
                  placeholderImage = genrealplaceholderImage;
                }
              
                return (
                    <a className="list" key={index} href={product.url} target={'_blank'} rel="noopener noreferrer">
                      {/* <div className="list-item item-product"> */}
                      <div className={product.type==="RECIPE" ? "list-item item-product item-recipe" : "list-item item-product"}>
                        <div className="item-image">
                          {/* <img src={product.images[0] ? product.images[0] : placeholderImage} className="item-pro" alt="product partners"/> */}
                          <LazyLoadImage
              placeholderSrc={placeholderImage}
			  className="item-pro" alt="product partners"
              effect="blur"
              src={product.images[0] ? product.images[0] : placeholderImage}
              onError={event => event.target.setAttribute('src', placeholderImage)}
            />

                        </div>
                        <div className="item-detail">
                          <h2 className="item-title">{product.title}</h2>
                          <p className="item-desc">{product.description.substring(0, 115) + (product.description.length > 115 ? "..." : "")}</p>
                          {/* <span className="item-price">{currencyFormatter(product.priceAmount, product.priceCurrency)}</span> */}
                          {product.type!=="RECIPE" &&<span className="item-price">{currencyFormatter(product.priceAmount, product.priceCurrency)}</span>}

                          <span className="item-by">By <strong>{product.siteName}</strong></span>
                        </div>
                      </div>
                    </a>
                )
              })
            }
            {
              (products.length < totalProducts) && (
                  <div className="text-center">
                    <button className="btn btn-loadmore" onClick={this.getPage} disabled={isLoading}>
                      {isLoading ? 'Loading...' : 'Load More'}
                    </button>
                  </div>
              )
            }
          </div>
        </div>
    );
  }
}

export default withRouter(App);
