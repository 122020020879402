const dev = {
  environment: 'development',
  api: {
    URL: 'http://192.168.0.124:65534/api/v1'
  },
  auth: {
    CLIENT_ID: 'someid',
    CLIENT_SECRET: 'somesecret',
    CLIENT_DEVICE: 'web',
  },
};

const stage = {
  environment: 'staging',
  api: {
    URL: 'http://192.168.0.124:65534/api/v1'
  },
  auth: {
    CLIENT_ID: 'someid',
    CLIENT_SECRET: 'somesecret',
    CLIENT_DEVICE: 'web',
  },
};


const prod = {
  environment: 'production',
  api: {
    URL: 'https://www.veegs.shop/api/v1'
  },
  auth: {
    CLIENT_ID: 'klIInoAGtFmdvGJv',
    CLIENT_SECRET: '9FN76LbvKdDKBORc86rZZvHxKFTfQGAZ',
    CLIENT_DEVICE: 'web',
  },
};

let config;
switch (process.env.REACT_APP_STAGE) {
  case 'dev':
    config = dev;
    break;
  case 'stage':
    config = stage;
    break;
  case 'prod':
    config = prod;
    break;
  default:
    config = dev;
    break;
}

export default {
  ...config
};
