import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';

import App from './App';

import './assets/App.scss';
const createHistory = require("history").createBrowserHistory;

const history = createHistory();

const root = (
    <Router history={history}>
      <App/>
    </Router>
);

ReactDOM.render(root, document.getElementById('root'));
